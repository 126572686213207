@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.headerWrapper {
  background: $background-color;
  height: 58px;
  padding: 4px 0 14px;

  @media (min-width: $lg) {
    height: auto;
    padding: 0;

    &.isSticky {
      background-color: $white-color;
      box-shadow: 0 3px 6px rgba(0,0,0,0.3);
    }
  }
}

.header {
  align-items: center;
  justify-content: flex-start;
  background: $background-color;
  display: flex;
  flex-direction: column;
  margin-top: 6px;

  @media (min-width: $lg) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 6px;
    margin-top: 0;

    .isSticky & {
      background-color: $white-color;
    }
  }
}

.headerTitle {
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-family: Montserrat, 'sans-serif';
  letter-spacing: 0;
  margin: 20px 0 0;

  @media (min-width: $lg) {
    margin: 16px auto 0 24px;

    &:before {
      content: '';
      display: inline-block;
      background: $grey-light-color;
      height: 32px;
      margin-right: 28px;
      width: 1px;
    }
  }
}

.headerLogo {
  height: 34px;

  > div {
    height: 100%;
  }

  @media (min-width: $lg) {
    height: 53px;
  }

}
.hideHeaderDesktop {
  @media (min-width: $md) {
    display: none;
  }
}
