//@import './media';

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  width: 100%;

  //@media (min-width: $xl){
  //  max-width: 1360px;
  //  margin: 0 auto;
  //}
}

.stickyContainer {
  flex-grow: 1;
}
