// Colors

$background-color: #F8F9F8; // primary background
$text-color: #000000;
$text-color-2: #404040;
$text-color-3: #666666;
$white-color: #FFFFFF;
$violent-color: #646C9A;
$violent-dark-color: #494E6D;
$violent-light-color: #9197BF;
$grey-color: #52575D;
$silver-color: #B3B8BD;
$black-squeeze-color: #EBF2F7;
$grey-light-color: #A19DAF;
$blue-color: #5A90BC;
$blue-dark-color: #3B5998;
$dew-color: #F6FFFE;
$placeholder-color: #7A7A7A;
$campaign-color: #646C9A;

$green-color: #19AE3C;
$yellow-color: #C9C300;
$red-color: #C91500;
$orange-color: #975040;
$blue-bright-color: #508FF4;
$purple-color: #9A2F9E;

$facebook-color: #1F8EFA;
$twitter-color: #1DA1F2;
$tiktok-color: #69C9D0;
$instagram-color: #E1306C;

$focus: 1px dotted $violent-color;

$error: #FF0000;
