@import '../../styles/colors';
@import '../../styles/media';

.modal {
  background: $white-color;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  padding: 16px 0 30px 0;
  width: 90%;
  overflow-y: auto;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform .5s ease;
  z-index: 100;

  &.shown {
    transform: translateX(0px);
    box-shadow: 0 0 6px $background-color;
  }

  @media (min-width: $sm) {
    width: 97%;
  }

  @media (min-width: $lg) {
    &.desktopDisable {
      display: block;
      background: transparent;
      overflow-y: inherit;
      position: inherit;
      height: auto;
      max-width: inherit;
      left: auto;
      top: auto;
      padding: 0;
      width: auto;
      transform: none;
    }

    &.isDesktop {
      transition: none;
    }
  }
}
