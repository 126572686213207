@import '../../styles/colors';
@import '../../styles/media';

.menu {
  list-style: none;
  padding: 0 0 0 36px;
  margin: 10px 0 3rem;
  li {
    border-bottom: 1px solid $background-color;
    padding: 18px 0 20px;

    &:nth-last-child(2) {
      margin-top: 128px;
    }
  }
  a, button {
    color: $text-color;
    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1.125rem;
    letter-spacing: 1px;
    text-decoration: none;
  }
  button {
    background: none;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .menuActive {
    a, button {
      color: $violent-color;
    }
  }

  @media (min-width: $lg) {
    display: flex;
    margin: 0;
    padding-left: 0;
    padding-top: 26px;
    padding-bottom: 10px;
    li {
      border: 0;
      padding: 0 18px;

      &:nth-last-child(2) {
        margin-top: 0;
      }

      &.hide {
        display: none;
      }

      a, button {
        color: $grey-color;
        font-size: 1rem;
        letter-spacing: 0;
        transition: color .5s ease;

        &:after {
          width: 0;
        }
      }

      &.menuActive a,
      &.menuActive button,
      a, button {
        &:after {
          background-color: $text-color;
          content: '';
          display: block;
          height: 2px;
          transition: width .5s ease;
        }
      }

      &.menuActive a,
      &.menuActive button,
      a:hover, button:hover{
        color: $text-color;

        &:after {
          width: 100%;
        }
      }
    }
    .logout {
      margin-left: 50px;
      padding-right: 0;
    }
  }
}

.menuBottom {
  list-style: none;
  margin: auto 0 0;
  padding: 0 0 0 36px;

  li {
    margin-bottom: 5px;
  }

  a, button {
    font-family: 'MontserratMediumItalic', sans-serif;
    font-size: 0.75rem;
    color: $grey-light-color;
    text-decoration: none;
  }

  @media (min-width: $lg) {
    display: none;
  }
}

.menuBottomActive {
  a, button {
    font-family: 'MontserratSemiBoldItalic', sans-serif;
  }
}
