@import-normalize ;
@import './styles/colors';
@import './styles/media';

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratMedium';
  src: url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratLight';
  src: url(./assets/fonts/Montserrat-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratItalic';
  src: url(./assets/fonts/Montserrat-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratLightItalic';
  src: url(./assets/fonts/Montserrat-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratMediumItalic';
  src: url(./assets/fonts/Montserrat-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratSemiBoldItalic';
  src: url(./assets/fonts/Montserrat-SemiBoldItalic.ttf) format('truetype');
}

html, body {
  width: 100%;
  height: 100%;
}

.popup-open {
  #root {
    position: relative;

    &:after {
      background: rgba(0, 0, 0, 0.3);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 20;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  background: $background-color;
  box-sizing: border-box;
  color: $text-color;
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.2;
  min-width: 320px;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
span:focus, {
  outline: $focus;
}

.container {
  padding: 34px 0 84px;

  &--lower {
    padding-top: 50px;
  }

  @media (min-width: $lg) {
    padding: 72px 0 70px;
  }

  @media (min-width: $xl) {
    &--lower {
      padding-top: 125px;
    }
  }
}

.container--flex {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.container--dashboard {
  overflow: hidden;
  padding-top: 65px;
}

.wrapper {
  margin: 0 $baseMargin;
  @media (min-width: $lg) {
    &__inner {
      max-width: $contentWidth-v4;
    }
  }
  @media (min-width: $md) {
    margin: 0 $baseMarginMobile;
  }
  @media (min-width: $xl) {
    max-width: $contentWidth;
    margin: 0 auto;
    padding-left: $basePadding;
    padding-right: $basePadding;

    &--v2 {
      max-width: $contentWidth-v2;
    }

    &--v3 {
      max-width: $contentWidth-v3;
    }
  }
}

.section {
  padding: 42px 0 70px;

  &--padding-top-small {
    padding-top: 5px;
  }

  &--white {
    background: $white-color;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--no-padding-top {
    padding-top: 0;
  }

  @media (min-width: $lg) {
    padding: 240px 0;

    &--no-padding-bottom {
      padding-bottom: 0;
    }

    &--no-padding-top {
      padding-top: 0;
    }

    &--padding-top-small {
      padding-top: 42px;
    }

    &--padding-top-smaller {
      padding-top: 140px;
    }

    &--padding-top-smaller-v2 {
      padding-top: 125px;
    }
    &--padding-bottom-smaller {
      padding-bottom: 172px;
    }
  }
}

h1, .h1 {
  font-family: MontserratSemiBold, sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 50px;

  @media (min-width: $lg) {
    font-size: 2.5rem; //40px
    letter-spacing: 2px;
    margin-bottom: 25px;
    margin-top: 0;
  }
}

.h1--margin-small {
  margin-bottom: 14px;

  @media (min-width: $lg) {
    margin-bottom: 25px;
  }
}

h2, .h2 {
  font-family: MontserratSemiBold, sans-serif;
  font-weight: 400;
  font-size: 1.375rem; //22px
  line-height: 1.3;
  margin-bottom: 36px;
  margin-top: 0;
  letter-spacing: 1px;

  &--bigger-mobile {
    font-size: 2.25rem; //36px
  }

  &--bigger-mobile-v2 {
    font-size: 1.875rem; //30px
    margin-bottom: 20px;
  }

  &--margin-less-mobile {
    margin-bottom: 6px;
  }

  @media (min-width: $lg) {
    letter-spacing: 2px;
    margin-bottom: 2rem;
    font-size: 2.25rem; //36px

    &--bigger-mobile {
      margin-bottom: 2rem;
    }
  }
}

.h3 {
  font-size: 1.25rem; //20px
  font-family: MontserratSemiBold, sans-serif;
  font-weight: 400;
  margin-bottom: 34px;
  margin-top: 10px;

  @media (min-width: $lg) {
    font-size: 1.875rem; //30px ?

    &--v2 {
      font-size: 2.25rem; //36px
      margin-bottom: 80px;
    }
  }
}

.title-34 {
  font-size: 1.375rem; //22px
  font-family: MontserratSemiBold, sans-serif;
  font-weight: 400;
  letter-spacing: 2px;

  @media (min-width: $lg) {
    font-size: 2.125rem; //34px
  }
}

p {
  font-family: MontserratLight, sans-serif;
  font-size: 1.25rem; //18px
  line-height: 1.5;

  @media (min-width: $lg) {
    font-size: 1.5rem; //24px
  }
}

.text-medium {
  font-size: 1.125rem; //18px
  line-height: 1.6;
  letter-spacing: 1px;
  margin-bottom: 1.125rem;

  @media (min-width: $sm) {
    font-size: 1.375rem; //22px
  }

  @media (min-width: $xl) {
    font-size: 1.5rem; //24px
    line-height: 1.5;
    letter-spacing: 1.6px;
    margin-bottom: 1.625rem;

    &--v2 {
      margin-bottom: 2.125rem; //34px
    }
  }

}

.text-light {
  font-family: MontserratLight, sans-serif !important;
}

.text-regular {
  font-family: Montserrat, sans-serif !important;
}

.text-bold {
  font-family: MontserratSemiBold, sans-serif !important;
}

.text-m {
  font-family: MontserratMedium, sans-serif !important;
}

.text-italic {
  font-family: MontserratItalic, sans-serif !important;
}

.text-blue {
  color: $blue-bright-color;
}

.text-blue-grey {
  color: $blue-color;
}

.link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.center {
  text-align: center;
}

.center-mobile {
  text-align: center;

  @media (min-width: $lg) {
    text-align: left;
  }
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.desktop-hide {
  @media (min-width: $lg) {
    display: none;
  }
}

.mobile-hide {
  display: none;
  @media (min-width: $lg) {
    display: inherit;
  }
}

.mb34 {
  margin-bottom: 34px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb18 {
  margin-bottom: 18px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb45-l {
  @media (min-width: $lg) {
    margin-bottom: 45px;
  }
}

.mb65-l {
  @media (min-width: $lg) {
    margin-bottom: 65px;
  }
}

.mb75-l {
  @media (min-width: $lg) {
    margin-bottom: 75px;
  }
}

.mb90-l {
  @media (min-width: $lg) {
    margin-bottom: 90px;
  }
}

.mt-35 {
  @media (min-width: $lg) {
    transform: translateY(-35px);
  }
}

.warning {
  display: flex;
  font-size: 12px;
  margin-top: 26px;

  &:before {
    background-image: url(./assets/images/icons/icon-warning.svg);
    background-repeat: no-repeat;
    background-size: 27px 29px;
    content: '';
    flex: 1 0 27px;
    display: inline-block;
    height: 29px;
    width: 27px;
    max-width: 27px;
    margin-right: 11px;
  }

  &--big {
    font-size: 1rem;
    align-items: center;
  }

  &--icon-small {
    &:before {
      background-size: 18px 16px;
      flex: 1 0 18px;
      height: 16px;
      width: 18px;
      max-width: 18px;
      margin-right: 6px;
    }
  }
}

.idea {
  display: flex;
  font-family: MontserratLightItalic, sans-serif;
  font-size: 12px;
  align-items: flex-start;

  &:before {
    background-image: url(./assets/images/icons/icon-idea.svg);
    background-repeat: no-repeat;
    background-size: 14px 21px;
    content: '';
    flex: 1 0 14px;
    display: inline-block;
    height: 21px;
    width: 14px;
    max-width: 14px;
    margin-right: 7px;
  }
}

.fz12 {
  font-size: 12px !important;
}

.fz13 {
  font-size: 13px !important;
}

.fz16 {
  font-size: 16px !important;
}

.lh22 {
  line-height: 22px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.color-campaign {
  color: $campaign-color !important;
}

.va-middle {
  vertical-align: middle;
}

b, strong {
  font-family: MontserratSemiBold, 'sans-serif';
  font-weight: normal;
}

.df {
  display: flex;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcs-l {
  @media (min-width: $lg) {
    justify-content: flex-start;
  }
}

.w60-ml {
  @media (max-width: $lg) {
    width: 60px;
  }
}

.shadow {
  @media (min-width: $lg) {
    filter: drop-shadow(60px 60px 60px rgba(0, 0, 0, 0.16));
    margin-right: 120px;
  }
}
